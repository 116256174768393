@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7))
    }
}

.slider {
    height: 150px;
    width: '100%';
    margin: auto;
    overflow: hidden;
    position: relative;


}

&::before,
&::after {
    content: "";
    height: 150px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

&::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

&::before {
    left: 0;
    top: 0;
}

.slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slide {
    height: 150px;
    width: 250px;
    justify-content: 'center';
}

/* Track */
::-webkit-scrollbar {
    width: 8px;
    /* width of the scrollbar */
    height: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #47E0E2;
    /* color of the handle */
    border-radius: 0px;
    /* roundness of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* color of the handle on hover */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the track */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
    background: #ddd;
    /* color of the track on hover */
}